exports.components = {
  "component---src-feature-page-types-cms-page-cms-page-template-jsx": () => import("./../../../src/feature/page-types/cms-page/cmsPageTemplate.jsx" /* webpackChunkName: "component---src-feature-page-types-cms-page-cms-page-template-jsx" */),
  "component---src-feature-page-types-contact-contact-template-jsx": () => import("./../../../src/feature/page-types/contact/contactTemplate.jsx" /* webpackChunkName: "component---src-feature-page-types-contact-contact-template-jsx" */),
  "component---src-feature-page-types-forms-form-template-jsx": () => import("./../../../src/feature/page-types/forms/formTemplate.jsx" /* webpackChunkName: "component---src-feature-page-types-forms-form-template-jsx" */),
  "component---src-feature-page-types-product-category-product-category-template-jsx": () => import("./../../../src/feature/page-types/product-category/productCategoryTemplate.jsx" /* webpackChunkName: "component---src-feature-page-types-product-category-product-category-template-jsx" */),
  "component---src-feature-page-types-product-group-product-group-template-jsx": () => import("./../../../src/feature/page-types/product-group/productGroupTemplate.jsx" /* webpackChunkName: "component---src-feature-page-types-product-group-product-group-template-jsx" */),
  "component---src-feature-page-types-publication-publication-listing-template-jsx": () => import("./../../../src/feature/page-types/publication/publicationListingTemplate.jsx" /* webpackChunkName: "component---src-feature-page-types-publication-publication-listing-template-jsx" */),
  "component---src-feature-page-types-publication-publication-template-jsx": () => import("./../../../src/feature/page-types/publication/publicationTemplate.jsx" /* webpackChunkName: "component---src-feature-page-types-publication-publication-template-jsx" */),
  "component---src-feature-page-types-search-results-search-results-template-jsx": () => import("./../../../src/feature/page-types/search-results/searchResultsTemplate.jsx" /* webpackChunkName: "component---src-feature-page-types-search-results-search-results-template-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-html-js": () => import("./../../../src/pages/html.js" /* webpackChunkName: "component---src-pages-html-js" */)
}

